export const dataEncuesta2 = [
  {
    id: 1,
    year: "2025",
    monthDoc: "FEB",
    title:
      "Primer informe Proceso Electoral Extraordinario  2024-2025.",
    link: "https://itetlax.org.mx/assets/pdf/Encuestas2/1.pdf",
  },


  {
    id: 2,
    year: "2025",
    monthDoc: "FEB",
    title:"Segundo informe Proceso Electoral Extraordinario 2024-2025.",
    link: "https://itetlax.org.mx/assets/pdf/Encuestas2/2.pdf",
  },

  {
    id: 3,
    year: "2025",
    monthDoc: "MAR",
    title:"Tercer informe Proceso Electoral Extraordinario 2024-2025.",
    link: "https://itetlax.org.mx/assets/pdf/Encuestas2/3.pdf",
  },
];
